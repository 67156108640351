let localConfig = {};
try {
    localConfig = require("./local-config");
} catch (e) {}

const defaultFlags = {};

const urlMap = {
    // Newcrest.
    "crowd.newcrest.com.au": {
        theme: "newcrest",
        gaTrackingId: "UA-43016849-18",
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        sso_enabled: true,
        algoliaIndexName: "prod_newcrest_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },
    "staging-5em2ouy-kvw7wxrqahxhg.au.platformsh.site": {
        theme: "newcrest",
        gaTrackingId: "",
        innovatorBaseHost:
            "https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site",
        dataScienceBaseHost:
            "https://cms.humyn.ai.development-q5nzhaa-ailver3isw3iu.au.platformsh.site",
        sso_enabled: true,
        algoliaIndexName: "dev_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml-dev.unearthed.solutions/",
        flags: defaultFlags,
    },

    // Unearthed.
    "crowd-staging.unearthed.solutions": {
        theme: "unearthed",
        gaTrackingId: "",
        innovatorBaseHost:
            "https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site",
        dataScienceBaseHost:
            "https://cms.humyn.ai.development-q5nzhaa-ailver3isw3iu.au.platformsh.site",
        algoliaIndexName: "dev_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml-test.unearthed.solutions/",
        flags: defaultFlags,
    },
    "build-challenge-7sypbcq-ya7t2ul3y6774.au.platformsh.site": {
        theme: "unearthed",
        gaTrackingId: "",
        innovatorBaseHost:
            "https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site",
        dataScienceBaseHost:
            "https://cms.humyn.ai.development-q5nzhaa-ailver3isw3iu.au.platformsh.site",
        algoliaIndexName: "dev_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml-dev.unearthed.solutions/",
        flags: defaultFlags,
    },
    "crowd.unearthed.solutions": {
        theme: "unearthed",
        gaTrackingId: "UA-43016849-19",
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        algoliaIndexName: "prod_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },
    localhost: {
        theme: "unearthed",
        gaTrackingId: "",
        innovatorBaseHost: "https://innovator.lndo.site",
        dataScienceBaseHost: "https://cms.lndo.site",
        algoliaIndexName: "local_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml-test.unearthed.solutions/",
        flags: defaultFlags,
    },

    // Origin.
    "origin.unearthed.solutions": {
        theme: "origin",
        gaTrackingId: "",
        sso_enabled: true,
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        algoliaIndexName: "prod_origin_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },
    "development-q5nzhaa-pydzqbovpad6e.au.platformsh.site": {
        theme: "origin",
        gaTrackingId: "",
        innovatorBaseHost:
            "https://development-q5nzhaa-lbjz5xns2dzm2.au.platformsh.site",
        dataScienceBaseHost:
            "https://cms.humyn.ai.development-q5nzhaa-ailver3isw3iu.au.platformsh.site",
        sso_enabled: true,
        algoliaIndexName: "dev_unearthed_challenge_platform",
        crowdmlUrl: "https://crowdml-dev.unearthed.solutions/",
        flags: defaultFlags,
    },

    // OZ Minerals.
    "ozminerals.unearthed.solutions": {
        theme: "ozminerals",
        gaTrackingId: "",
        sso_enabled: false,
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        algoliaIndexName: "prod_ozminerals_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },
    "tad.unearthed.solutions": {
        theme: "ozminerals",
        gaTrackingId: "",
        sso_enabled: false,
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        algoliaIndexName: "prod_ozminerals_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },

    "master-7rqtwti-h6g7gy4wnjl7s.au.platformsh.site": {
        theme: "ozminerals",
        gaTrackingId: "",
        sso_enabled: false,
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },

    // OZ Minerals.
    "south32.unearthed.solutions": {
        theme: "south32",
        gaTrackingId: "",
        innovatorBaseHost: "https://unearthed.solutions",
        dataScienceBaseHost: "https://cms.humyn.ai",
        algoliaIndexName: "prod_south32_challenge_platform",
        crowdmlUrl: "https://crowdml.unearthed.solutions/",
        flags: defaultFlags,
    },
};

const themeMap = {
    newcrest: {
        siteName: "Newcrest Crowd",
        primaryColor: "#ac8b3b",
    },
    unearthed: {
        siteName: "Unearthed Crowd",
        primaryColor: "#FF6600",
        multiOrganisations: true,
    },
    south32: {
        siteName: "South32 Crowd",
        primaryColor: "#FFDF00",
    },
    origin: {
        siteName: "Origin Crowd",
        primaryColor: "#ec0001",
    },
    ozminerals: {
        siteName: "Oz Minerals Crowd",
        primaryColor: "#035D55",
    },
};

function getActiveTheme() {
    let activeTheme = "unearthed";

    if (typeof window !== "undefined") {
        if (urlMap[window.location.hostname]) {
            activeTheme = urlMap[window.location.hostname].theme;
        }
    } else if (
        typeof global.process !== "undefined" &&
        process.env.VERCEL_GIT_COMMIT_REF
    ) {
        // Map between platform project id's and the theme we want to use.
        switch (process.env.VERCEL_GIT_COMMIT_REF) {
            case "master-newcrest":
                activeTheme = "newcrest";
                break;
            case "master-origin":
                activeTheme = "origin";
                break;
            case "master-ozminerals":
                activeTheme = "ozminerals";
                break;
            case "master-south32":
                activeTheme = "south32";
                break;
            case "master":
            default:
                activeTheme = "unearthed";
                break;
        }
    }
    return activeTheme;
}

function getTrackingCode() {
    if (
        typeof window === "undefined" ||
        typeof urlMap[window.location.hostname] === "undefined"
    ) {
        return "";
    }
    return urlMap[window.location.hostname].gaTrackingId;
}

function getPrimaryColor() {
    return themeMap[getActiveTheme()].primaryColor;
}

function getSiteName() {
    return themeMap[getActiveTheme()].siteName;
}

function multiOrganisations() {
    return !!themeMap[getActiveTheme()].multiOrganisations;
}

function getDataScienceBaseUrl() {
    return getDataScienceBaseHost();
}

function getInnovatorPortalBaseUrl() {
    return getInnovatorPortalBaseHost() + "/u";
}

function getBackendBaseUrl() {
    if (typeof window === "undefined") {
        return "";
    }
    if (window.location.hostname.indexOf("vercel.app") !== -1) {
        return "https://staging-5em2ouy-ya7t2ul3y6774.au.platformsh.site";
    }

    switch (window.location.hostname) {
        case "localhost":
            return "https://challenge-platform.lndo.site";

        // Vercel staging go to platform staging backend
        case "crowd-staging.unearthed.solutions":
            return "https://staging-5em2ouy-ya7t2ul3y6774.au.platformsh.site";

        // CI is different to local.
        case "127.0.0.1":
        case "frontend":
            return "http://backend";

        default:
            // Convert the url to get the backend location
            // e.g. convert "crowd.unearthed.solutions" to "crowd-backend.unearthed.solutions"
            const hostArr = window.location.hostname.split(".");
            hostArr[0] += "-backend";
            return "https://" + hostArr.join(".");
    }
}

function getSsoUrlIfEnabled(path = "/") {
    if (isSsoEnabled()) {
        return `${
            getConfig().backendBaseUrl
        }/saml_login?ReturnTo=${encodeURIComponent(
            window.location.origin + path,
        )}`;
    }
    return "";
}

function isSsoEnabled() {
    if (
        typeof window === "undefined" ||
        typeof urlMap[window.location.hostname] === "undefined"
    ) {
        return false;
    }
    return urlMap[window.location.hostname].sso_enabled === true;
}

function getInnovatorPortalBaseHost() {
    if (typeof window === "undefined") {
        return "https://innovator.lndo.site";
    }
    if (typeof urlMap[window.location.hostname] === "undefined") {
        if (window.location.hostname.indexOf("vercel.app") !== -1) {
            return "https://staging-5em2ouy-ya7t2ul3y6774.au.platformsh.site";
        }
        return "https://innovator.lndo.site";
    }

    return urlMap[window.location.hostname].innovatorBaseHost;
}

function getDataScienceBaseHost() {
    if (typeof window === "undefined") {
        return "https://cms.lndo.site";
    }
    if (typeof urlMap[window.location.hostname] === "undefined") {
        if (window.location.hostname.indexOf("vercel.app") !== -1) {
            return "https://cms.humyn.ai.development-q5nzhaa-ailver3isw3iu.au.platformsh.site";
        }
        return "https://cms.lndo.site";
    }

    return urlMap[window.location.hostname].dataScienceBaseHost;
}

function getAlgoliaIndexName() {
    if (
        typeof window === "undefined" ||
        typeof urlMap[window.location.hostname] === "undefined"
    ) {
        return "gitlab_unearthed_challenge_platform";
    }
    return urlMap[window.location.hostname].algoliaIndexName;
}

function getCrowdmlUrl() {
    if (
        typeof window === "undefined" ||
        typeof urlMap[window.location.hostname] === "undefined"
    ) {
        return "https://crowdml-test.unearthed.solutions/";
    }
    return urlMap[window.location.hostname].crowdmlUrl;
}

function getFlags() {
    if (
        typeof window === "undefined" ||
        typeof urlMap[window.location.hostname] === "undefined"
    ) {
        return defaultFlags;
    }
    return urlMap[window.location.hostname].flags;
}

var config = null;
function getConfig() {
    if (config === null) {
        config = {
            activeTheme: getActiveTheme(),
            gaCode: getTrackingCode(),
            primaryColor: getPrimaryColor(),
            innovatorPortalBaseUrl: getInnovatorPortalBaseUrl(),
            innovatorPortalBaseHost: getInnovatorPortalBaseHost(),
            dataScienceBaseUrl: getDataScienceBaseUrl(),
            dataScienceBaseHost: getDataScienceBaseUrl(),
            siteName: getSiteName(),
            sso_url: getSsoUrlIfEnabled,
            sso_enabled: isSsoEnabled(),
            multiOrganisations: multiOrganisations(),
            algoliaIndexName: getAlgoliaIndexName(),
            themeMap: themeMap,
            crowdmlUrl: getCrowdmlUrl(),
            backendBaseUrl: getBackendBaseUrl(),
            flags: getFlags(),
        };
        if (typeof localConfig === "object") {
            Object.keys(localConfig).forEach(key => {
                config[key] = localConfig[key];
            });
        }
    }
    return config;
}

module.exports = getConfig;
