const getConfig = require("./instance-config");

module.exports = {
    unearthed: {
        adminColor: "#ffc107",
        logo: "/assets/unearthed/logo.png",
        logo_square: "/assets/unearthed/logo-square.png",
        logoStyles: {
            width: "140px",
        },
        primaryColor: getConfig().themeMap.unearthed.primaryColor,
        darkGrey: "#3E3E3E",
        lightGrey: "#E5E5E5",
        lighterGrey: "#F5F5F5",
        blueGrey: "#4C4E5D",
        greyBlack: "#2C2C2C",
        green: "#52C41A",
        red: "#DD3134",
        orange: "#FFBF00",
    },
    newcrest: {
        adminColor: "#ffc107",
        logo: "/assets/newcrest/newcrest.png",
        logo_square: "/assets/newcrest/logo-square.png",
        logoStyles: {
            height: "45px",
        },
        primaryColor: getConfig().themeMap.newcrest.primaryColor,
        darkGrey: "#3E3E3E",
        lightGrey: "#E5E5E5",
        lighterGrey: "#F5F5F5",
        blueGrey: "#4C4E5D",
        greyBlack: "#2C2C2C",
        green: "#52C41A",
        red: "#DD3134",
        orange: "#FFBF00",
    },
    origin: {
        adminColor: "#ffc107",
        logo: "/assets/origin/logo.png",
        logo_square: "/assets/origin/logo-square.png",
        logoStyles: {
            height: "60px",
        },
        primaryColor: getConfig().themeMap.origin.primaryColor,
        darkGrey: "#3E3E3E",
        lightGrey: "#E5E5E5",
        lighterGrey: "#F5F5F5",
        blueGrey: "#4C4E5D",
        greyBlack: "#2C2C2C",
        green: "#52C41A",
        red: "#DD3134",
        orange: "#FFBF00",
    },
    south32: {
        adminColor: "#ffc107",
        logo: "/assets/south32/logo.png",
        logo_square: "/assets/south32/logo-square.png",
        logoStyles: {
            width: "140px",
        },
        primaryColor: getConfig().themeMap.south32.primaryColor,
        primaryColorTextForeground: "#2e3639",
        darkGrey: "#3E3E3E",
        lightGrey: "#E5E5E5",
        lighterGrey: "#F5F5F5",
        blueGrey: "#4C4E5D",
        greyBlack: "#2C2C2C",
        green: "#52C41A",
        red: "#DD3134",
        orange: "#FFBF00",
    },
    ozminerals: {
        adminColor: "#ffc107",
        logo: "/assets/ozminerals/tad.png",
        logo_square: "/assets/ozminerals/tad-square.png",
        logoStyles: {
            height: "50px",
        },
        primaryColor: getConfig().themeMap.ozminerals.primaryColor,
        darkGrey: "#3E3E3E",
        lightGrey: "#E5E5E5",
        lighterGrey: "#F5F5F5",
        blueGrey: "#4C4E5D",
        greyBlack: "#2C2C2C",
        green: "#52C41A",
        red: "#DD3134",
        orange: "#FFBF00",
    },
};
