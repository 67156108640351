import React, { useState } from "react";
import {
    BulbOutlined,
    CompassOutlined,
    HomeOutlined,
    SettingOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../themes";
import withPermissions from "../../hoc/withPermissions";
import adminMenuItems from "./adminMenuItems";
import { hasPermission } from "../../helpers/HasPermission";

const MenuWrapper = styled.div`
    width: ${props => (props.isCollapsed ? "80px" : "256px")};
    transition: width 0.2s;
    position: fixed;
    z-index: 50;
    top: 0;
    bottom: 0;

    .ant-menu {
        padding: 0 16px !important;
    }

    .ant-menu,
    .ant-menu-submenu > .ant-menu {
        background: #f5f5f5 !important;
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
        display: flex;
        align-items: center;
    }

    .ant-menu-submenu-title .anticon,
    .ant-menu-item .anticon {
        font-size: 24px !important;
        line-height: 0 !important;
    }

    .ant-menu-inline-collapsed > .ant-menu-item {
        padding: 0 2px !important;
    }

    .ant-menu-item {
        padding: 0 2px !important;
        margin: 32px 10px !important;

        a {
            display: flex;
            align-items: center;
            color: ${props => props.theme.darkGrey};

            &:hover {
                color: ${props => props.theme.primaryColor};
            }
        }
    }

    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 8px !important;
    }

    .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 8px !important;
        margin: 32px 4px 8px 4px !important;
    }

    .ant-menu-submenu-title,
    .ant-menu-sub .ant-menu-item {
        margin: 8px 4px !important;
    }

    .ant-menu-inline-collapsed > .menu-item-logo {
        padding: 0 !important;
    }

    .menu-item-logo {
        padding: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        height: 47px !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: none;
        &:after {
            display: none;
        }

        > a {
            color: ${props => props.theme.primaryColor};
        }
    }

    a {
        color: ${props => props.theme.darkGrey};
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Logo = styled.div`
    margin: 0px;
    display: block;
`;
const MenuName = styled.span`
    color: ${props => props.theme.darkGrey};
    margin-left: 2px;
    margin-top: ${props => (props.noSpace ? "2px" : "4px")};

    &:hover {
        color: ${props => props.theme.darkGrey};
    }
`;

const VerticalNav = ({ activeMenuItem, permissions }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    return (
        <MenuWrapper isCollapsed={isCollapsed}>
            <Menu
                className="hide-link-styles"
                onMouseOver={() => setIsCollapsed(false)}
                onMouseLeave={() => setIsCollapsed(true)}
                // onClick={() => setIsCollapsed(c => !c)}
                selectedKeys={[activeMenuItem]}
                defaultOpenKeys={[activeMenuItem]}
                mode="inline"
                theme="light"
                inlineCollapsed={isCollapsed}
                style={{
                    height: "100%",
                    overflowY: "auto",
                    borderRight: "1px solid #ddd",
                }}
            >
                <Menu.Item key="logo" className={"menu-item-logo"}>
                    <Link to={"/"}>
                        <LogoWrapper>
                            <Logo isCollapsed={isCollapsed}>
                                <img
                                    alt="Logo"
                                    style={{
                                        width: "auto",
                                        height: "47px",
                                    }}
                                    src={
                                        isCollapsed
                                            ? theme.logo_square || theme.logo
                                            : theme.logo
                                    }
                                />
                            </Logo>
                        </LogoWrapper>
                    </Link>
                </Menu.Item>

                <Menu.Divider />

                <Menu.Item key="home" className={"menu-item-home"}>
                    <Link to={"/"}>
                        <HomeOutlined />
                        <MenuName>Home</MenuName>
                    </Link>
                </Menu.Item>

                <Menu.Item key="challenges" className={"menu-item-challenge"}>
                    <Link to={"/challenges"}>
                        <CompassOutlined />
                        <MenuName>Challenges</MenuName>
                    </Link>
                </Menu.Item>

                <Menu.Item key="ideas" className={"menu-item-ideas"}>
                    <Link to={"/ideas"}>
                        <BulbOutlined />
                        <MenuName>Ideas</MenuName>
                    </Link>
                </Menu.Item>

                {hasPermission(permissions, "administer users") ||
                hasPermission(permissions, "administer task_workflow") ? (
                    <Menu.Divider />
                ) : null}

                {hasPermission(permissions, "administer users") ? (
                    <Menu.Item
                        key="user_management"
                        className="menu-item-user_management"
                    >
                        <Link to={"/admin/user-management"}>
                            <UsergroupAddOutlined />
                            <MenuName>Team Members</MenuName>
                        </Link>
                    </Menu.Item>
                ) : null}

                {hasPermission(permissions, "administer task_workflow") ? (
                    <Menu.SubMenu
                        key="admin"
                        className="menu-item-admin admin-menu"
                        title={
                            <>
                                <SettingOutlined />
                                <MenuName noSpace>Settings</MenuName>
                            </>
                        }
                    >
                        {adminMenuItems.map(item => (
                            <Menu.Item
                                key={item.key}
                                className={`menu-item-${item.key}`}
                            >
                                <Link className="nav-link" to={item.path}>
                                    {item.text}
                                </Link>
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ) : null}
            </Menu>
        </MenuWrapper>
    );
};

export default withPermissions(VerticalNav);
