import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./store";
import { ThemeProvider } from "styled-components";
import * as serviceWorker from "./serviceWorker";
import theme from "./themes";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import isTouchDevice from "./helpers/isTouchDevice";

let store = configureStore();

document.getElementsByTagName("body")[0].addEventListener("keypress", e => {
    if (e.charCode === 47 && e.target.tagName.toUpperCase() === "BODY") {
        e.preventDefault();
        document.querySelector(".global-search input").focus();
    }
});

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <div>
                <DndProvider
                    backend={isTouchDevice() ? TouchBackend : HTML5Backend}
                >
                    <App />
                </DndProvider>
            </div>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
