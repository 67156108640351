import { createGlobalStyle } from "styled-components";

const GlobalButtonStyles = createGlobalStyle`
    .ant-btn {
        color: ${props => props.theme.darkGrey} !important;
        border: 1px solid ${props => props.theme.primaryColor} !important;

        &:hover {
            border-color: #3E3E3E !important;   
        }

        &:disabled {
            color: rgba(0, 0, 0, 0.25) !important;
            background-color: #f5f5f5 !important;
            border-color: #d9d9d9 !important;
            text-shadow: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;

            &:hover {
                background-color: #f5f5f5 !important;
                color: rgba(0, 0, 0, 0.25) !important;
            }
        }
    }

    .ant-btn-primary {
        background-color: ${props => props.theme.primaryColor} !important;
        color: ${props =>
            props.theme.primaryColorTextForeground || "#ffffff"} !important;
        border: 1px solid ${props => props.theme.primaryColor} !important;
        
        &:hover {
            background-color: #ffffff !important;
            color: ${props => props.theme.darkGrey} !important;
        }
    }

    .ant-btn.ant-btn-link {
        color: ${props => props.theme.blueGrey} !important;
        font-weight: 500 !important;
        border: none !important;
        background-color: none !important;

        &:hover {
            color: ${props => props.theme.greyBlack} !important;
            box-shadow: none !important;
            text-decoration: underline !important;
        }
    }

    .ant-table-row {
        a {
            color: ${props => props.theme.blueGrey} !important;

            &:hover {
                color: ${props => props.theme.greyBlack} !important;
                text-decoration: underline;
            }
        }
    }

    .ant-input-search-button {
        background-color: #FFF !important;
        color: ${props => props.theme.darkGrey} !important;
        border: 1px solid #d9d9d9 !important;

        &:hover {
            border: 1px solid ${props => props.theme.primaryColor} !important;
        }
    }
    
    .ant-pagination-item-active {
        background-color: #FFF !important;
        border: 1px solid ${props => props.theme.primaryColor} !important;

        a {
            color: ${props => props.theme.darkGrey} !important;
        }
    }

    .ant-radio-group {
        .ant-radio-button-wrapper-checked {
            background: #ffffff !important;
            color: ${props => props.theme.darkGrey} !important;
            border-color: ${props => props.theme.primaryColor} !important;
            font-weight: 500;
        }
    }

    .ant-radio-button-wrapper {
        &:hover {
            border-color: ${props => props.theme.primaryColor} !important;
            color: ${props => props.theme.darkGrey}!important;
        }
    }

    .ant-tabs-nav {
        .ant-tabs-tab {
            color: ${props => props.theme.darkGrey}!important;
        
            &:hover {
                color: ${props => props.theme.greyBlack}!important;
            }
        }
    }

    .ant-menu-sub {
        .ant-menu-item > a {
            color: ${props => props.theme.blueGrey}!important;

            &:hover {
                color: ${props => props.theme.greyBlack}!important;
                text-decoration: underline;
            }
        }
    }


`;

export default GlobalButtonStyles;
